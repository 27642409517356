<template>
  <div>
    <c-table
      title="발의 전 부서장 검토항목"
      :columns="grid.columns"
      :gridHeight="gridHeight"
      :data="moc.reviews"
      :filtering="false"
      :usePaging="false"
      :columnSetting="false"
      :editable="editable&&!disabled"
      @table-data-change="tableDataChange"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.saveData"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveReview"
            @btnCallback="saveReviewCallback"/>
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'review-item',
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
        reviews: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'reviewItemName',
            field: 'reviewItemName',
            label: '검토항목',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'confirmFlag',
            field: 'confirmFlag',
            label: '검토 필요여부',
            align: 'center',
            style: 'width:100px',
            type: 'check',
            'false': 'N',
            'true': 'Y',
            sortable: false,
          },
          {
            name: 'reviewCompleteRequestDate',
            field: 'reviewCompleteRequestDate',
            label: '검토완료 요청일',
            align: 'center',
            style: 'width:100px',
            type: 'date',
            sortable: false,
            disableTarget: 'confirmFlag',
            disableCon: 'Y',
            value: '',
            setHeader: true,
          },
          {
            name: 'manageUserName',
            field: 'manageUserName',
            label: '담당자',
            align: 'center',
            style: 'width:100px',
            type: 'user',
            userId: 'manageUserId',
            sortable: false,
            disableTarget: 'confirmFlag',
            disableCon: 'Y',
            value: '',
            setHeader: true,
          },
          {
            name: 'reviewCompleteDate',
            field: 'reviewCompleteDate',
            label: '검토완료일',
            align: 'center',
            style: 'width:100px',
            type: 'date',
            sortable: false,
            disableTarget: 'confirmFlag',
            disableCon: 'Y',
            value: '',
            setHeader: true,
          },
          {
            name: 'reviewHistory',
            field: 'reviewHistory',
            label: '검토내역',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: false,
            disableTarget: 'confirmFlag',
            disableCon: 'Y',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:100px',
            type: 'textarea',
            sortable: false,
            disableTarget: 'confirmFlag',
            disableCon: 'Y',
          },
        ],
        saveData: {
          sopMocId: '',
          reviews: [],
        },
        data: [],
      },
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.height ? this.height : 'auto'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.moc.review.save.url
      // url setting
      // code setting
      // list setting
    },
    tableDataChange(props, col) {
      if (col.name === 'confirmFlag' && props.row[col.name] === 'N') {
        this.$set(props.row, 'manageUserName', '');
        this.$set(props.row, 'manageUserId', '');
        this.$set(props.row, 'reviewCompleteRequestDate', '');
        this.$set(props.row, 'reviewCompleteDate', '');
        this.$set(props.row, 'reviewHistory', '');
        this.$set(props.row, 'remark', '');
      }
    },
    saveReview() {
      if (this.$comm.validTable(this.grid.columns, this.moc.reviews)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.grid.saveData.sopMocId = this.moc.sopMocId;
            this.grid.saveData.reviews = this.$_.cloneDeep(this.moc.reviews);
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveReviewCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
  }
};
</script>
